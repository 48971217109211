const TW_WC_PROJECT_ID = "145769e410f16970a79ff77b2d89a1e0";
const WC_RELAY_URL = "wss://relay.walletconnect.com";

/**
 * @internal
 * Methods
 */
const EIP155_SIGNING_METHODS = {
  PERSONAL_SIGN: "personal_sign",
  ETH_SIGN: "eth_sign",
  ETH_SIGN_TRANSACTION: "eth_signTransaction",
  ETH_SIGN_TYPED_DATA: "eth_signTypedData",
  ETH_SIGN_TYPED_DATA_V3: "eth_signTypedData_v3",
  ETH_SIGN_TYPED_DATA_V4: "eth_signTypedData_v4",
  ETH_SEND_TRANSACTION: "eth_sendTransaction",
  SWITCH_CHAIN: "wallet_switchEthereumChain"
};

export { EIP155_SIGNING_METHODS as E, TW_WC_PROJECT_ID as T, WC_RELAY_URL as W };

import { a as assertWindowEthereum } from './assertWindowEthereum-88295886.browser.esm.js';

/**
 * @internal
 */
function getInjectedRabbyProvider() {
  if (assertWindowEthereum(globalThis.window)) {
    if (globalThis.window.ethereum && window.rabby) {
      return window.rabby;
    }
  }
}

export { getInjectedRabbyProvider as g };

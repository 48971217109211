/**
 * @internal
 */
function getInjectedXDEFIProvider() {
  if (globalThis.window && globalThis.window.xfi && globalThis.window.xfi.ethereum) {
    return globalThis.window.xfi.ethereum;
  }
}

export { getInjectedXDEFIProvider as g };

import { z } from 'zod';
import { v4 } from 'uuid';
import { utils } from 'ethers';

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);
const JsonSchema = z.lazy(() => z.union([literalSchema, z.array(JsonSchema), z.record(JsonSchema)]), {
  invalid_type_error: "Provided value was not valid JSON"
});
const AddressSchema = z.string().refine(arg => utils.isAddress(arg), out => {
  return {
    message: `${out} is not a valid address`
  };
});
const RawDateSchema = z.date().transform(i => {
  return Math.floor(i.getTime() / 1000);
});
const AccountTypeSchema = z.literal("evm");

const AuthenticationPayloadDataSchema = z.object({
  iss: z.string(),
  sub: z.string(),
  aud: z.string(),
  exp: RawDateSchema,
  nbf: RawDateSchema,
  iat: RawDateSchema,
  jti: z.string().default(() => v4()),
  ctx: JsonSchema.optional()
});
const AuthenticationPayloadSchema = z.object({
  payload: AuthenticationPayloadDataSchema,
  signature: z.string()
});
const AuthenticateOptionsSchema = z.object({
  domain: z.string(),
  issuerAddress: AddressSchema.optional(),
  validateTokenId: z.function().args(z.string()).optional()
});

const VerifyOptionsSchema = z.object({
  domain: z.string(),
  statement: z.string().optional(),
  uri: z.string().optional(),
  version: z.string().optional(),
  chainId: z.string().optional(),
  validateNonce: z.function().args(z.string()).optional(),
  resources: z.array(z.string()).optional()
});

const THIRDWEB_AUTH_COOKIE_PREFIX = `thirdweb_auth`;
const THIRDWEB_AUTH_TOKEN_COOKIE_PREFIX = `${THIRDWEB_AUTH_COOKIE_PREFIX}_token`;
const THIRDWEB_AUTH_ACTIVE_ACCOUNT_COOKIE = `${THIRDWEB_AUTH_COOKIE_PREFIX}_active_account`;
const THIRDWEB_AUTH_DEFAULT_LOGIN_PAYLOAD_DURATION_IN_SECONDS = 60 * 10;
const THIRDWEB_AUTH_DEFAULT_TOKEN_DURATION_IN_SECONDS = 60 * 60 * 24;
const THIRDWEB_AUTH_DEFAULT_REFRESH_INTERVAL_IN_SECONDS = 5;

const GenerateOptionsSchema = z.object({
  domain: z.string(),
  tokenId: z.string().optional(),
  expirationTime: z.date().default(() => new Date(Date.now() + 1000 * THIRDWEB_AUTH_DEFAULT_TOKEN_DURATION_IN_SECONDS)),
  invalidBefore: z.date().optional(),
  session: z.union([JsonSchema, z.function().args(z.string())]).optional(),
  verifyOptions: VerifyOptionsSchema.omit({
    domain: true
  }).optional()
});

const RefreshOptionsSchema = z.object({
  expirationTime: z.date().default(() => new Date(Date.now() + 1000 * THIRDWEB_AUTH_DEFAULT_TOKEN_DURATION_IN_SECONDS))
});

const LoginOptionsSchema = z.object({
  domain: z.string(),
  address: z.string().optional(),
  statement: z.string().optional(),
  uri: z.string().optional(),
  version: z.string().optional(),
  chainId: z.string().optional(),
  nonce: z.string().optional(),
  expirationTime: z.date().default(() => new Date(Date.now() + 1000 * THIRDWEB_AUTH_DEFAULT_LOGIN_PAYLOAD_DURATION_IN_SECONDS)),
  invalidBefore: z.date().default(() => new Date(Date.now() - 1000 * THIRDWEB_AUTH_DEFAULT_LOGIN_PAYLOAD_DURATION_IN_SECONDS)),
  resources: z.array(z.string()).optional()
});
const LoginPayloadDataSchema = z.object({
  type: AccountTypeSchema,
  domain: z.string(),
  address: z.string(),
  statement: z.string().default("Please ensure that the domain above matches the URL of the current website."),
  uri: z.string().optional(),
  version: z.string().default("1"),
  chain_id: z.string().optional(),
  nonce: z.string().default(() => v4()),
  issued_at: z.date().default(new Date()).transform(d => d.toISOString()),
  expiration_time: z.date().transform(d => d.toISOString()),
  invalid_before: z.date().default(new Date()).transform(d => d.toISOString()),
  resources: z.array(z.string()).optional()
});
const LoginPayloadSchema = z.object({
  payload: LoginPayloadDataSchema,
  signature: z.string()
});
const LoginPayloadOutputSchema = LoginPayloadSchema.extend({
  payload: LoginPayloadDataSchema.extend({
    issued_at: z.string(),
    expiration_time: z.string(),
    invalid_before: z.string()
  })
});

/**
 * Create an EIP-4361 & CAIP-122 compliant message to sign based on the login payload
 */
function createLoginMessage(payload) {
  const typeField = "Ethereum";
  const header = `${payload.domain} wants you to sign in with your ${typeField} account:`;
  let prefix = [header, payload.address].join("\n");
  prefix = [prefix, payload.statement].join("\n\n");
  if (payload.statement) {
    prefix += "\n";
  }
  const suffixArray = [];
  if (payload.uri) {
    const uriField = `URI: ${payload.uri}`;
    suffixArray.push(uriField);
  }
  const versionField = `Version: ${payload.version}`;
  suffixArray.push(versionField);
  if (payload.chain_id) {
    const chainField = `Chain ID: ` + payload.chain_id || "1";
    suffixArray.push(chainField);
  }
  const nonceField = `Nonce: ${payload.nonce}`;
  suffixArray.push(nonceField);
  const issuedAtField = `Issued At: ${payload.issued_at}`;
  suffixArray.push(issuedAtField);
  const expiryField = `Expiration Time: ${payload.expiration_time}`;
  suffixArray.push(expiryField);
  if (payload.invalid_before) {
    const invalidBeforeField = `Not Before: ${payload.invalid_before}`;
    suffixArray.push(invalidBeforeField);
  }
  if (payload.resources) {
    suffixArray.push([`Resources:`, ...payload.resources.map(x => `- ${x}`)].join("\n"));
  }
  const suffix = suffixArray.join("\n");
  return [prefix, suffix].join("\n");
}
async function buildLoginPayload(_ref) {
  let {
    wallet,
    options
  } = _ref;
  const parsedOptions = LoginOptionsSchema.parse(options);
  let chainId = parsedOptions?.chainId;
  if (!chainId && wallet.getChainId) {
    try {
      chainId = (await wallet.getChainId()).toString();
    } catch {
      // ignore error
    }
  }
  return LoginPayloadDataSchema.parse({
    type: wallet.type,
    domain: parsedOptions.domain,
    address: parsedOptions?.address || (await wallet.getAddress()),
    statement: parsedOptions?.statement,
    version: parsedOptions?.version,
    uri: parsedOptions?.uri,
    chain_id: chainId,
    nonce: parsedOptions?.nonce,
    expiration_time: parsedOptions.expirationTime,
    invalid_before: parsedOptions.invalidBefore,
    resources: parsedOptions?.resources
  });
}
async function signLoginPayload(_ref2) {
  let {
    wallet,
    payload
  } = _ref2;
  const message = createLoginMessage(payload);
  const signature = await wallet.signMessage(message);
  return {
    payload,
    signature
  };
}
async function buildAndSignLoginPayload(_ref3) {
  let {
    wallet,
    options
  } = _ref3;
  const payload = await buildLoginPayload({
    wallet,
    options
  });
  return signLoginPayload({
    wallet,
    payload
  });
}
async function verifyLoginPayload(_ref4) {
  let {
    wallet,
    payload,
    options
  } = _ref4;
  const parsedOptions = VerifyOptionsSchema.parse(options);
  if (payload.payload.type !== wallet.type) {
    throw new Error(`Expected chain type '${wallet.type}' does not match chain type on payload '${payload.payload.type}'`);
  }

  // Check that the intended domain matches the domain of the payload
  if (payload.payload.domain !== parsedOptions.domain) {
    throw new Error(`Expected domain '${parsedOptions.domain}' does not match domain on payload '${payload.payload.domain}'`);
  }

  // Check that the payload statement matches the expected statement
  if (parsedOptions?.statement) {
    if (payload.payload.statement !== parsedOptions.statement) {
      throw new Error(`Expected statement '${parsedOptions.statement}' does not match statement on payload '${payload.payload.statement}'`);
    }
  }

  // Check that the intended URI matches the URI of the payload
  if (parsedOptions?.uri) {
    if (payload.payload.uri !== parsedOptions.uri) {
      throw new Error(`Expected URI '${parsedOptions.uri}' does not match URI on payload '${payload.payload.uri}'`);
    }
  }

  // Check that the intended version matches the version of the payload
  if (parsedOptions?.version) {
    if (payload.payload.version !== parsedOptions.version) {
      throw new Error(`Expected version '${parsedOptions.version}' does not match version on payload '${payload.payload.version}'`);
    }
  }

  // Check that the intended chain ID matches the chain ID of the payload
  if (parsedOptions?.chainId) {
    if (payload.payload.chain_id !== parsedOptions.chainId) {
      throw new Error(`Expected chain ID '${parsedOptions.chainId}' does not match chain ID on payload '${payload.payload.chain_id}'`);
    }
  }

  // Check that the payload nonce is valid
  if (parsedOptions?.validateNonce !== undefined) {
    try {
      await parsedOptions.validateNonce(payload.payload.nonce);
    } catch (err) {
      throw new Error(`Login request nonce is invalid`);
    }
  }

  // Check that it isn't before the invalid before time
  const currentTime = new Date();
  if (currentTime < new Date(payload.payload.invalid_before)) {
    throw new Error(`Login request is not yet valid`);
  }

  // Check that the payload hasn't expired
  if (currentTime > new Date(payload.payload.expiration_time)) {
    throw new Error(`Login request has expired`);
  }

  // Check that the specified resources are present on the payload
  if (parsedOptions?.resources) {
    const missingResources = parsedOptions.resources.filter(resource => !payload.payload.resources?.includes(resource));
    if (missingResources.length > 0) {
      throw new Error(`Login request is missing required resources: ${missingResources.join(", ")}`);
    }
  }

  // Check that the signing address is the claimed wallet address
  const message = createLoginMessage(payload.payload);
  const chainId = wallet.type === "evm" && payload.payload.chain_id ? parseInt(payload.payload.chain_id) : undefined;
  const verified = await wallet.verifySignature(message, payload.signature, payload.payload.address, chainId);
  if (!verified) {
    throw new Error(`Signer address does not match payload address '${payload.payload.address.toLowerCase()}'`);
  }
  return payload.payload.address;
}

function base64encode(data) {
  {
    return window.btoa(data);
  }
}
function base64decode(data) {
  {
    return window.atob(data);
  }
}

/**
 * Build JWT token based on the authentication payload
 */
async function buildJWT(_ref) {
  let {
    wallet,
    payload
  } = _ref;
  const payloadData = AuthenticationPayloadDataSchema.parse(payload);
  const message = JSON.stringify(payloadData);
  const signature = await wallet.signMessage(message);

  // Header used for JWT token specifying hash algorithm
  const header = {
    // Specify ECDSA with SHA-256 for hashing algorithm
    alg: "ES256",
    typ: "JWT"
  };
  const encodedHeader = base64encode(JSON.stringify(header));
  const encodedData = base64encode(JSON.stringify(payloadData));
  const encodedSignature = base64encode(signature);

  // Generate a JWT with base64 encoded header, payload, and signature
  const jwt = `${encodedHeader}.${encodedData}.${encodedSignature}`;
  return jwt;
}

/**
 * Generate a new JWT using a login payload
 */
async function generateJWT(_ref2) {
  let {
    wallet,
    payload,
    options
  } = _ref2;
  const parsedOptions = GenerateOptionsSchema.parse(options);
  const userAddress = await verifyLoginPayload({
    wallet,
    payload,
    options: {
      domain: parsedOptions.domain,
      ...parsedOptions.verifyOptions
    }
  });
  let session = undefined;
  if (typeof parsedOptions?.session === "function") {
    const sessionTrigger = await parsedOptions.session(userAddress);
    if (sessionTrigger) {
      session = sessionTrigger;
    }
  } else {
    session = parsedOptions?.session;
  }
  const adminAddress = await wallet.getAddress();
  return buildJWT({
    wallet,
    payload: {
      iss: adminAddress,
      sub: userAddress,
      aud: parsedOptions.domain,
      nbf: parsedOptions?.invalidBefore || new Date(),
      exp: parsedOptions.expirationTime,
      iat: new Date(),
      jti: parsedOptions?.tokenId,
      ctx: session
    }
  });
}

/**
 * Parse data from an encoded auth JWT
 */
function parseJWT(jwt) {
  const encodedPayload = jwt.split(".")[1];
  const encodedSignature = jwt.split(".")[2];
  const payload = JSON.parse(base64decode(encodedPayload));
  const signature = base64decode(encodedSignature);
  return {
    payload,
    signature
  };
}

/**
 * Refresh an existing JWT
 */
async function refreshJWT(_ref3) {
  let {
    wallet,
    jwt,
    options
  } = _ref3;
  const {
    payload
  } = parseJWT(jwt);
  const parsedOptions = RefreshOptionsSchema.parse(options);
  return buildJWT({
    wallet,
    payload: {
      iss: payload.iss,
      sub: payload.sub,
      aud: payload.aud,
      nbf: new Date(),
      exp: parsedOptions.expirationTime,
      iat: new Date(),
      ctx: payload.ctx
    }
  });
}

/**
 * Validate a JWT and extract the user's info
 */
async function authenticateJWT(_ref4) {
  let {
    wallet,
    jwt,
    options
  } = _ref4;
  const parsedOptions = AuthenticateOptionsSchema.parse(options);
  const {
    payload,
    signature
  } = parseJWT(jwt);

  // Check that the payload unique ID is valid
  if (parsedOptions?.validateTokenId !== undefined) {
    try {
      await parsedOptions.validateTokenId(payload.jti);
    } catch (err) {
      throw new Error(`Token ID is invalid`);
    }
  }

  // Check that the token audience matches the domain
  if (payload.aud !== parsedOptions.domain) {
    throw new Error(`Expected token to be for the domain '${parsedOptions.domain}', but found token with domain '${payload.aud}'`);
  }

  // Check that the token is past the invalid before time
  const currentTime = Math.floor(new Date().getTime() / 1000);
  if (currentTime < payload.nbf) {
    throw new Error(`This token is invalid before epoch time '${payload.nbf}', current epoch time is '${currentTime}'`);
  }

  // Check that the token hasn't expired
  if (currentTime > payload.exp) {
    throw new Error(`This token expired at epoch time '${payload.exp}', current epoch time is '${currentTime}'`);
  }

  // Check that the connected wallet matches the token issuer
  const issuerAddress = parsedOptions.issuerAddress ? parsedOptions.issuerAddress : await wallet.getAddress();
  if (issuerAddress.toLowerCase() !== payload.iss.toLowerCase()) {
    throw new Error(`The expected issuer address '${issuerAddress}' did not match the token issuer address '${payload.iss}'`);
  }
  let chainId = undefined;
  if (wallet.getChainId) {
    try {
      chainId = await wallet.getChainId();
    } catch {
      // ignore error
    }
  }
  const verified = await wallet.verifySignature(JSON.stringify(payload), signature, issuerAddress, chainId);
  if (!verified) {
    throw new Error(`The expected signer address '${issuerAddress}' did not sign the token`);
  }
  return {
    address: payload.sub,
    session: payload.ctx
  };
}

class ThirdwebAuth {
  constructor(wallet, domain) {
    this.wallet = wallet;
    this.domain = domain;
  }
  updateWallet(wallet) {
    this.wallet = wallet;
  }
  async payload(options) {
    return buildLoginPayload({
      wallet: this.wallet,
      options: this.formatOptions(options)
    });
  }
  async loginWithPayload(payload) {
    return signLoginPayload({
      wallet: this.wallet,
      payload
    });
  }
  async login(options) {
    return buildAndSignLoginPayload({
      wallet: this.wallet,
      options: this.formatOptions(options)
    });
  }
  async verify(payload, options) {
    return verifyLoginPayload({
      wallet: this.wallet,
      payload,
      options: this.formatOptions(options)
    });
  }
  async generate(payload, options) {
    return generateJWT({
      wallet: this.wallet,
      payload,
      options: this.formatOptions(options)
    });
  }
  async refresh(jwt, expirationTime) {
    return refreshJWT({
      wallet: this.wallet,
      jwt,
      options: {
        expirationTime
      }
    });
  }
  async authenticate(jwt, options) {
    return authenticateJWT({
      wallet: this.wallet,
      jwt,
      options: this.formatOptions(options)
    });
  }
  parseToken(jwt) {
    return parseJWT(jwt);
  }
  formatOptions(options) {
    return options ? {
      ...options,
      domain: options?.domain || this.domain
    } : {
      domain: this.domain
    };
  }
}

export { AuthenticationPayloadDataSchema as A, GenerateOptionsSchema as G, JsonSchema as J, LoginOptionsSchema as L, RawDateSchema as R, ThirdwebAuth as T, VerifyOptionsSchema as V, authenticateJWT as a, buildJWT as b, buildLoginPayload as c, buildAndSignLoginPayload as d, AuthenticationPayloadSchema as e, AuthenticateOptionsSchema as f, generateJWT as g, AddressSchema as h, AccountTypeSchema as i, LoginPayloadDataSchema as j, LoginPayloadSchema as k, LoginPayloadOutputSchema as l, RefreshOptionsSchema as m, THIRDWEB_AUTH_ACTIVE_ACCOUNT_COOKIE as n, THIRDWEB_AUTH_TOKEN_COOKIE_PREFIX as o, parseJWT as p, THIRDWEB_AUTH_DEFAULT_REFRESH_INTERVAL_IN_SECONDS as q, refreshJWT as r, signLoginPayload as s, THIRDWEB_AUTH_DEFAULT_TOKEN_DURATION_IN_SECONDS as t, verifyLoginPayload as v };

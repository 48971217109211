import { a as walletConnect } from './safeChainSlug-bca88c60.browser.esm.js';
import '@thirdweb-dev/wallets';
import './formElements-556d0493.browser.esm.js';
import 'react/jsx-runtime';
import '@radix-ui/react-icons';
import '@thirdweb-dev/react-core';
import '@emotion/react';
import 'react';
import '@radix-ui/colors';
import '@emotion/styled';
import 'detect-browser';
import 'qrcode';

/**
 * @deprecated Use `walletConnect` instead
 *
 * The WalletConnect v1.0 protocol has been shut down and no longer works.
 * To avoid breaking change, `walletConnectV1` is still available but is an alias of `walletConnect`.
 *
 * @internal
 */
const walletConnectV1 = walletConnect;

export { walletConnectV1 };

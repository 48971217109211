import { a as assertWindowEthereum } from './assertWindowEthereum-88295886.browser.esm.js';

/**
 * @internal
 */
function getInjectedOKXProvider() {
  if (assertWindowEthereum(globalThis.window)) {
    if (globalThis.window.ethereum && window.okxwallet) {
      return window.okxwallet;
    }
  }
}

export { getInjectedOKXProvider as g };

/**
 * Allows overriding transaction behavior for this contract
 * @internal
 */
class ContractInterceptor {
  constructor(contractWrapper) {
    this.contractWrapper = contractWrapper;
  }

  /**
   * The next transaction executed will add/replace any overrides passed via the passed in hook.
   * @remarks Overridden values will be applied to the next transaction executed.
   * @example
   * ```javascript
   * contract.interceptor.overrideNextTransaction(() => ({
   *   gasLimit: 3000000,
   * }));
   * ```
   * @param hook - the hook to add or replace any CallOverrides (gas limit, gas price, nonce, from, value, etc...)
   * @public
   */
  overrideNextTransaction(hook) {
    this.contractWrapper.withTransactionOverride(hook);
  }
}

export { ContractInterceptor as C };

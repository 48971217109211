/**
 * @internal
 */
function getInjectedMagicEdenProvider() {
  if (globalThis.window && globalThis.window.magicEden && globalThis.window.magicEden.ethereum) {
    return globalThis.window.magicEden.ethereum;
  }
}

export { getInjectedMagicEdenProvider as g };

import { a as assertWindowEthereum } from './assertWindowEthereum-88295886.browser.esm.js';

/**
 * @internal
 */
function getInjectedCoin98Provider() {
  if (assertWindowEthereum(globalThis.window)) {
    if (window.coin98) {
      return window.ethereum;
    }
  }
}

export { getInjectedCoin98Provider as g };

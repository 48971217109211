import { a as assertWindowEthereum } from './assertWindowEthereum-88295886.browser.esm.js';

/**
 * @internal
 */
function getInjectedCoreWalletProvider() {
  if (assertWindowEthereum(globalThis.window)) {
    if (globalThis.window.ethereum && window.avalanche) {
      return window.avalanche;
    }
  }
}

export { getInjectedCoreWalletProvider as g };

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Multicall3__factory = exports.Multicall2__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var Multicall2__factory_1 = require("./Multicall2__factory");
Object.defineProperty(exports, "Multicall2__factory", { enumerable: true, get: function () { return Multicall2__factory_1.Multicall2__factory; } });
var Multicall3__factory_1 = require("./Multicall3__factory");
Object.defineProperty(exports, "Multicall3__factory", { enumerable: true, get: function () { return Multicall3__factory_1.Multicall3__factory; } });

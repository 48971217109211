import CIDTool from 'cid-tool';
import { sha256HexSync } from '@thirdweb-dev/crypto';
import FormData from 'form-data';
import { v4 } from 'uuid';

function getProcessEnv(key) {
  let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  if (typeof process !== "undefined") {
    if (process.env[key]) {
      return process.env[key];
    }
  }
  return defaultValue;
}

const TW_HOSTNAME_SUFFIX = ".ipfscdn.io";
const TW_STAGINGHOSTNAME_SUFFIX = ".thirdwebstorage-staging.com";
const TW_GATEWAY_URLS = [`https://{clientId}${TW_HOSTNAME_SUFFIX}/ipfs/{cid}/{path}`];

/**
 * @internal
 * @param url - the url to check
 * @returns
 */
function isTwGatewayUrl(url) {
  const hostname = new URL(url).hostname;
  const isProd = hostname.endsWith(TW_HOSTNAME_SUFFIX);
  if (isProd) {
    return true;
  }
  // fall back to also handle staging urls
  return hostname.endsWith(TW_STAGINGHOSTNAME_SUFFIX);
}
const PUBLIC_GATEWAY_URLS = ["https://{cid}.ipfs.cf-ipfs.com/{path}", "https://{cid}.ipfs.dweb.link/{path}", "https://ipfs.io/ipfs/{cid}/{path}", "https://cloudflare-ipfs.com/ipfs/{cid}/{path}", "https://{cid}.ipfs.w3s.link/{path}", "https://w3s.link/ipfs/{cid}/{path}", "https://nftstorage.link/ipfs/{cid}/{path}", "https://gateway.pinata.cloud/ipfs/{cid}/{path}"];

/**
 * @internal
 */
const DEFAULT_GATEWAY_URLS = {
  // Note: Gateway URLs should have trailing slashes (we clean this on user input)
  "ipfs://": [...TW_GATEWAY_URLS, ...PUBLIC_GATEWAY_URLS]
};

/**
 * @internal
 */
const TW_UPLOAD_SERVER_URL = getProcessEnv("CUSTOM_UPLOAD_SERVER_URL", "https://storage.thirdweb.com");

/**
 * @internal
 */
const PINATA_IPFS_URL = `https://api.pinata.cloud/pinning/pinFileToIPFS`;

/**
 * @internal
 */
function parseGatewayUrls(gatewayUrls) {
  if (Array.isArray(gatewayUrls)) {
    return {
      "ipfs://": gatewayUrls
    };
  }
  return gatewayUrls || {};
}

/**
 * @internal
 */
function getGatewayUrlForCid(gatewayUrl, cid, clientId) {
  const parts = cid.split("/");
  const hash = convertCidToV1(parts[0]);
  const filePath = parts.slice(1).join("/");
  let url = gatewayUrl;

  // If the URL contains {cid} or {path} tokens, replace them with the CID and path
  // Both tokens must be present for the URL to be valid
  if (gatewayUrl.includes("{cid}") && gatewayUrl.includes("{path}")) {
    url = url.replace("{cid}", hash).replace("{path}", filePath);
  }
  // If the URL contains only the {cid} token, replace it with the CID
  else if (gatewayUrl.includes("{cid}")) {
    url = url.replace("{cid}", hash);
  }
  // If those tokens don't exist, use the canonical gateway URL format
  else {
    url += `${hash}/${filePath}`;
  }
  // if the URL contains the {clientId} token, replace it with the client ID
  if (gatewayUrl.includes("{clientId}")) {
    if (!clientId) {
      throw new Error("Cannot use {clientId} in gateway URL without providing a client ID");
    }
    url = url.replace("{clientId}", clientId);
  }
  return url;
}

/**
 * @internal
 */
function prepareGatewayUrls(gatewayUrls, clientId, secretKey) {
  const allGatewayUrls = {
    ...DEFAULT_GATEWAY_URLS,
    ...gatewayUrls
  };
  for (const key of Object.keys(allGatewayUrls)) {
    const cleanedGatewayUrls = allGatewayUrls[key].map(url => {
      // inject clientId when present
      if (clientId && url.includes("{clientId}")) {
        return url.replace("{clientId}", clientId);
      } else if (secretKey && url.includes("{clientId}")) {
        // should only be used on Node.js in a backend/script context
        if (typeof window !== "undefined") {
          throw new Error("Cannot use secretKey in browser context");
        }
        const hashedSecretKey = sha256HexSync(secretKey);
        const derivedClientId = hashedSecretKey.slice(0, 32);
        return url.replace("{clientId}", derivedClientId);
      } else if (url.includes("{clientId}")) {
        // if no client id passed, filter out the url
        return undefined;
      } else {
        return url;
      }
    }).filter(url => url !== undefined);
    allGatewayUrls[key] = cleanedGatewayUrls;
  }
  return allGatewayUrls;
}

/**
 * @internal
 */
function convertCidToV1(cid) {
  let normalized = '';
  try {
    const hash = cid.split("/")[0];
    normalized = CIDTool.base32(hash);
  } catch (e) {
    throw new Error(`The CID ${cid} is not valid.`);
  }
  return normalized;
}

/**
 * @internal
 */
function isBrowser() {
  return typeof window !== "undefined";
}

/**
 * @internal
 */
function isFileInstance(data) {
  return global.File && data instanceof File;
}

/**
 * @internal
 */
function isBufferInstance(data) {
  return global.Buffer && data instanceof Buffer;
}

/**
 * @internal
 */
function isBufferOrStringWithName(data) {
  return !!(data && data.name && data.data && typeof data.name === "string" && (typeof data.data === "string" || isBufferInstance(data.data)));
}
function isFileOrBuffer(data) {
  return isFileInstance(data) || isBufferInstance(data) || isBufferOrStringWithName(data);
}

/**
 * @internal
 */
function isFileBufferOrStringEqual(input1, input2) {
  if (isFileInstance(input1) && isFileInstance(input2)) {
    // if both are File types, compare the name, size, and last modified date (best guess that these are the same files)
    if (input1.name === input2.name && input1.lastModified === input2.lastModified && input1.size === input2.size) {
      return true;
    }
  } else if (isBufferInstance(input1) && isBufferInstance(input2)) {
    // buffer gives us an easy way to compare the contents!

    return input1.equals(input2);
  } else if (isBufferOrStringWithName(input1) && isBufferOrStringWithName(input2)) {
    // first check the names
    if (input1.name === input2.name) {
      // if the data for both is a string, compare the strings
      if (typeof input1.data === "string" && typeof input2.data === "string") {
        return input1.data === input2.data;
      } else if (isBufferInstance(input1.data) && isBufferInstance(input2.data)) {
        // otherwise we know it's buffers, so compare the buffers
        return input1.data.equals(input2.data);
      }
    }
  }
  // otherwise if we have not found a match, return false
  return false;
}

/**
 * @internal
 */
function parseCidAndPath(gatewayUrl, uri) {
  const regexString = gatewayUrl.replace("{cid}", "(?<hash>[^/]+)").replace("{path}", "(?<path>[^?#]+)");
  const regex = new RegExp(regexString);
  const match = uri.match(regex);
  if (match) {
    const hash = match.groups?.hash;
    const path = match.groups?.path;
    const queryString = uri.includes("?") ? uri.substring(uri.indexOf("?") + 1) : "";
    return {
      hash,
      path,
      query: queryString
    };
  }
}

/**
 * @internal
 */
function replaceGatewayUrlWithScheme(uri, gatewayUrls) {
  for (const scheme of Object.keys(gatewayUrls)) {
    for (const gatewayUrl of gatewayUrls[scheme]) {
      // If the url is a tokenized url, we need to convert it to a canonical url
      // Otherwise, we just need to check if the url is a prefix of the uri
      if (gatewayUrl.includes("{cid}")) {
        // Given the url is a tokenized url, we need to lift the cid and the path from the uri
        const parsed = parseCidAndPath(gatewayUrl, uri);
        if (parsed?.hash && parsed?.path) {
          const queryString = parsed?.query ? `?${parsed?.query}` : "";
          return `${scheme}${parsed?.hash}/${parsed?.path}${queryString}`;
        } else {
          // If we can't lift the cid and path from the uri, we can't replace the gateway url, return the orig string
          return uri;
        }
      } else if (uri.startsWith(gatewayUrl)) {
        return uri.replace(gatewayUrl, scheme);
      }
    }
  }
  return uri;
}

/**
 * @internal
 */
function replaceSchemeWithGatewayUrl(uri, gatewayUrls) {
  let index = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  let clientId = arguments.length > 3 ? arguments[3] : undefined;
  const scheme = Object.keys(gatewayUrls).find(s => uri.startsWith(s));
  const schemeGatewayUrls = scheme ? gatewayUrls[scheme] : [];
  if (!scheme && index > 0 || scheme && index >= schemeGatewayUrls.length) {
    return undefined;
  }
  if (!scheme) {
    return uri;
  }
  const path = uri.replace(scheme, "");
  try {
    const gatewayUrl = getGatewayUrlForCid(schemeGatewayUrls[index], path, clientId);
    return gatewayUrl;
  } catch (err) {
    console.warn(`The IPFS uri: ${path} is not valid.`);
    return undefined;
  }
}

/**
 * @internal
 */
function replaceObjectGatewayUrlsWithSchemes(data, gatewayUrls) {
  if (typeof data === "string") {
    return replaceGatewayUrlWithScheme(data, gatewayUrls);
  }
  if (typeof data === "object") {
    if (!data) {
      return data;
    }
    if (isFileOrBuffer(data)) {
      return data;
    }
    if (Array.isArray(data)) {
      return data.map(entry => replaceObjectGatewayUrlsWithSchemes(entry, gatewayUrls));
    }
    return Object.fromEntries(Object.entries(data).map(_ref => {
      let [key, value] = _ref;
      return [key, replaceObjectGatewayUrlsWithSchemes(value, gatewayUrls)];
    }));
  }
  return data;
}

/**
 * @internal
 */
function replaceObjectSchemesWithGatewayUrls(data, gatewayUrls, clientId) {
  if (typeof data === "string") {
    return replaceSchemeWithGatewayUrl(data, gatewayUrls, 0, clientId);
  }
  if (typeof data === "object") {
    if (!data) {
      return data;
    }
    if (isFileOrBuffer(data)) {
      return data;
    }
    if (Array.isArray(data)) {
      return data.map(entry => replaceObjectSchemesWithGatewayUrls(entry, gatewayUrls, clientId));
    }
    return Object.fromEntries(Object.entries(data).map(_ref2 => {
      let [key, value] = _ref2;
      return [key, replaceObjectSchemesWithGatewayUrls(value, gatewayUrls, clientId)];
    }));
  }
  return data;
}

/**
 * @internal
 */
function extractObjectFiles(data) {
  let files = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  // If item is a FileOrBuffer add it to our list of files
  if (isFileOrBuffer(data)) {
    files.push(data);
    return files;
  }
  if (typeof data === "object") {
    if (!data) {
      return files;
    }
    if (Array.isArray(data)) {
      data.forEach(entry => extractObjectFiles(entry, files));
    } else {
      Object.keys(data).map(key => extractObjectFiles(data[key], files));
    }
  }
  return files;
}

/**
 * @internal
 */
function replaceObjectFilesWithUris(data, uris) {
  if (isFileOrBuffer(data)) {
    if (uris.length) {
      data = uris.shift();
      return data;
    } else {
      console.warn("Not enough URIs to replace all files in object.");
    }
  }
  if (typeof data === "object") {
    if (!data) {
      return data;
    }
    if (Array.isArray(data)) {
      return data.map(entry => replaceObjectFilesWithUris(entry, uris));
    } else {
      return Object.fromEntries(Object.entries(data).map(_ref3 => {
        let [key, value] = _ref3;
        return [key, replaceObjectFilesWithUris(value, uris)];
      }));
    }
  }
  return data;
}

var pkg = {
	name: "@thirdweb-dev/storage",
	version: "2.0.10",
	main: "dist/thirdweb-dev-storage.cjs.js",
	module: "dist/thirdweb-dev-storage.esm.js",
	exports: {
		".": {
			module: "./dist/thirdweb-dev-storage.esm.js",
			"default": "./dist/thirdweb-dev-storage.cjs.js"
		},
		"./package.json": "./package.json"
	},
	repository: "https://github.com/thirdweb-dev/js/tree/main/packages/storage",
	author: "thirdweb eng <eng@thirdweb.com>",
	license: "Apache-2.0",
	sideEffects: false,
	scripts: {
		format: "prettier --write 'src/**/*'",
		lint: "eslint src/ && bunx publint --strict --level warning",
		fix: "eslint src/ --fix",
		clean: "rm -rf dist/",
		build: "tsc && preconstruct build",
		"test:all": "NODE_ENV=test SWC_NODE_PROJECT=./tsconfig.test.json mocha --timeout 30000 --parallel './test/**/*.test.ts'",
		test: "pnpm test:all",
		"test:single": "NODE_ENV=test SWC_NODE_PROJECT=./tsconfig.test.json mocha --timeout 30000",
		push: "yalc push",
		typedoc: "node scripts/typedoc.mjs"
	},
	files: [
		"dist/"
	],
	preconstruct: {
		exports: true
	},
	devDependencies: {
		"@babel/preset-env": "^7.23.8",
		"@babel/preset-typescript": "^7.23.3",
		"@microsoft/api-documenter": "^7.22.30",
		"@microsoft/api-extractor": "^7.36.3",
		"@microsoft/tsdoc": "^0.14.1",
		"@preconstruct/cli": "2.7.0",
		"@swc-node/register": "^1.6.8",
		"@thirdweb-dev/tsconfig": "workspace:*",
		"@types/chai": "^4.3.5",
		"@types/mocha": "^10.0.0",
		"@types/uuid": "^9.0.5",
		"@typescript-eslint/eslint-plugin": "^6.2.0",
		"@typescript-eslint/parser": "^6.19.1",
		chai: "^4.3.6",
		eslint: "^8.56.0",
		"eslint-config-thirdweb": "workspace:*",
		"eslint-plugin-tsdoc": "^0.2.16",
		esm: "^3.2.25",
		mocha: "^10.2.0",
		rimraf: "^3.0.2",
		"typedoc-gen": "workspace:*",
		typescript: "^5.3.3"
	},
	dependencies: {
		"@thirdweb-dev/crypto": "workspace:*",
		"cid-tool": "^3.0.0",
		"form-data": "^4.0.0",
		uuid: "^9.0.1"
	},
	engines: {
		node: ">=18"
	}
};

/**
 * @internal
 *
 * The code below comes from the package https://github.com/DamonOehlman/detect-browser
 */
const operatingSystemRules = [["iOS", /iP(hone|od|ad)/], ["Android OS", /Android/], ["BlackBerry OS", /BlackBerry|BB10/], ["Windows Mobile", /IEMobile/], ["Amazon OS", /Kindle/], ["Windows 3.11", /Win16/], ["Windows 95", /(Windows 95)|(Win95)|(Windows_95)/], ["Windows 98", /(Windows 98)|(Win98)/], ["Windows 2000", /(Windows NT 5.0)|(Windows 2000)/], ["Windows XP", /(Windows NT 5.1)|(Windows XP)/], ["Windows Server 2003", /(Windows NT 5.2)/], ["Windows Vista", /(Windows NT 6.0)/], ["Windows 7", /(Windows NT 6.1)/], ["Windows 8", /(Windows NT 6.2)/], ["Windows 8.1", /(Windows NT 6.3)/], ["Windows 10", /(Windows NT 10.0)/], ["Windows ME", /Windows ME/], ["Windows CE", /Windows CE|WinCE|Microsoft Pocket Internet Explorer/], ["Open BSD", /OpenBSD/], ["Sun OS", /SunOS/], ["Chrome OS", /CrOS/], ["Linux", /(Linux)|(X11)/], ["Mac OS", /(Mac_PowerPC)|(Macintosh)/], ["QNX", /QNX/], ["BeOS", /BeOS/], ["OS/2", /OS\/2/]];
function detectOS(ua) {
  for (let ii = 0, count = operatingSystemRules.length; ii < count; ii++) {
    const result = operatingSystemRules[ii];
    if (!result) {
      continue;
    }
    const [os, regex] = result;
    const match = regex.exec(ua);
    if (match) {
      return os;
    }
  }
  return null;
}

function getOperatingSystem() {
  if (typeof navigator !== "undefined" && navigator.product === "ReactNative") {
    return "";
  } else if (typeof window !== "undefined") {
    const userAgent = navigator.userAgent;
    return detectOS(userAgent) || "";
  } else {
    return process.platform;
  }
}

function setAnalyticsHeaders(headers) {
  const globals = getAnalyticsGlobals();
  headers["x-sdk-version"] = globals.x_sdk_version;
  headers["x-sdk-name"] = globals.x_sdk_name;
  headers["x-sdk-platform"] = globals.x_sdk_platform;
  headers["x-sdk-os"] = globals.x_sdk_os;
}
function setAnalyticsHeadersForXhr(xhr) {
  const globals = getAnalyticsGlobals();
  xhr.setRequestHeader("x-sdk-version", globals.x_sdk_version);
  xhr.setRequestHeader("x-sdk-os", globals.x_sdk_os);
  xhr.setRequestHeader("x-sdk-name", globals.x_sdk_name);
  xhr.setRequestHeader("x-sdk-platform", globals.x_sdk_platform);
  xhr.setRequestHeader("x-bundle-id", globals.app_bundle_id);
}
function getAnalyticsGlobals() {
  if (typeof globalThis === "undefined") {
    return {
      x_sdk_name: pkg.name,
      x_sdk_platform: getPlatform(),
      x_sdk_version: pkg.version,
      x_sdk_os: getOperatingSystem(),
      app_bundle_id: undefined
    };
  }
  if (globalThis.X_SDK_NAME === undefined) {
    globalThis.X_SDK_NAME = pkg.name;
    globalThis.X_SDK_PLATFORM = getPlatform();
    globalThis.X_SDK_VERSION = pkg.version;
    globalThis.X_SDK_OS = getOperatingSystem();
    globalThis.APP_BUNDLE_ID = undefined;
  }
  return {
    x_sdk_name: globalThis.X_SDK_NAME,
    x_sdk_platform: globalThis.X_SDK_PLATFORM,
    x_sdk_version: globalThis.X_SDK_VERSION,
    x_sdk_os: globalThis.X_SDK_OS,
    app_bundle_id: globalThis.APP_BUNDLE_ID || "" // if react, this will be empty
  };
}
function getPlatform() {
  return typeof navigator !== "undefined" && navigator.product === "ReactNative" ? "mobile" : typeof window !== "undefined" ? "browser" : "node";
}

/**
 * Default downloader used - handles downloading from all schemes specified in the gateway URLs configuration.
 *
 * @example
 * ```jsx
 * // Can instantiate the downloader with the default gateway URLs
 * const downloader = new StorageDownloader();
 *
 * // client id if used in client-side applications
 * const clientId = "your-client-id";
 * const storage = new ThirdwebStorage({ clientId, downloader });
 *
 * // secret key if used in server-side applications
 * const secretKey = "your-secret-key";
 * const storage = new ThirdwebStorage({ secretKey, downloader });
 * ```
 *
 * @public
 */
class StorageDownloader {
  DEFAULT_TIMEOUT_IN_SECONDS = 60;
  DEFAULT_MAX_RETRIES = 3;
  constructor(options) {
    this.secretKey = options.secretKey;
    this.clientId = options.clientId;
    this.defaultTimeout = options.timeoutInSeconds || this.DEFAULT_TIMEOUT_IN_SECONDS;
  }
  async download(uri, gatewayUrls, options) {
    let attempts = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    const maxRetries = options?.maxRetries || this.DEFAULT_MAX_RETRIES;
    if (attempts > maxRetries) {
      console.error("[FAILED_TO_DOWNLOAD_ERROR] Failed to download from URI - too many attempts failed.");
      // return a 404 response to avoid retrying
      return new Response(JSON.stringify({
        error: "Not Found"
      }), {
        status: 404,
        headers: {
          "Content-Type": "application/json"
        }
      });
    }

    // Replace recognized scheme with the highest priority gateway URL that hasn't already been attempted
    let resolvedUri = replaceSchemeWithGatewayUrl(uri, gatewayUrls, attempts, this.clientId);
    // If every gateway URL we know about for the designated scheme has been tried (via recursion) and failed, throw an error
    if (!resolvedUri) {
      console.error("[FAILED_TO_DOWNLOAD_ERROR] Unable to download from URI - all gateway URLs failed to respond.");
      return new Response(JSON.stringify({
        error: "Not Found"
      }), {
        status: 404,
        headers: {
          "Content-Type": "application/json"
        }
      });
    } else if (attempts > 0) {
      console.warn(`Retrying download with backup gateway URL: ${resolvedUri}`);
    }
    let headers = {};
    if (isTwGatewayUrl(resolvedUri)) {
      const bundleId = getAnalyticsGlobals().app_bundle_id;
      if (this.secretKey) {
        headers = {
          "x-secret-key": this.secretKey
        };
      } else if (this.clientId) {
        if (!resolvedUri.includes("bundleId")) {
          resolvedUri = resolvedUri + (bundleId ? `?bundleId=${bundleId}` : "");
        }
        headers["x-client-Id"] = this.clientId;
      }
      // if we have a authorization token on global context then add that to the headers
      if (typeof globalThis !== "undefined" && "TW_AUTH_TOKEN" in globalThis && typeof globalThis.TW_AUTH_TOKEN === "string") {
        headers = {
          ...headers,
          authorization: `Bearer ${globalThis.TW_AUTH_TOKEN}`
        };
      }
      if (typeof globalThis !== "undefined" && "TW_CLI_AUTH_TOKEN" in globalThis && typeof globalThis.TW_CLI_AUTH_TOKEN === "string") {
        headers = {
          ...headers,
          authorization: `Bearer ${globalThis.TW_CLI_AUTH_TOKEN}`
        };
        headers["x-authorize-wallet"] = "true";
      }
      setAnalyticsHeaders(headers);
    }
    if (isTooManyRequests(resolvedUri)) {
      // skip the request if we're getting too many request error from the gateway
      return this.download(uri, gatewayUrls, options, attempts + 1);
    }
    const controller = new AbortController();
    const timeoutInSeconds = options?.timeoutInSeconds || this.defaultTimeout;
    const timeout = setTimeout(() => controller.abort(), timeoutInSeconds * 1000);
    const resOrErr = await fetch(resolvedUri, {
      headers,
      signal: controller.signal
    }).catch(err => err);
    // if we get here clear the timeout
    if (timeout) {
      clearTimeout(timeout);
    }
    if (!("status" in resOrErr)) {
      // early exit if we don't have a status code
      throw new Error(`Request timed out after ${timeoutInSeconds} seconds. ${isTwGatewayUrl(resolvedUri) ? "You can update the timeoutInSeconds option to increase the timeout." : "You're using a public IPFS gateway, pass in a clientId or secretKey for a reliable IPFS gateway."}`);
    }

    // if the request is good we can skip everything else
    if (resOrErr.ok) {
      return resOrErr;
    }
    if (resOrErr.status === 429) {
      // track that we got a too many requests error
      tooManyRequestsBackOff(resolvedUri, resOrErr);
      // Since the current gateway failed, recursively try the next one we know about
      return this.download(uri, gatewayUrls, options, attempts + 1);
    }
    if (resOrErr.status === 410) {
      // Don't retry if the content is blocklisted
      console.error(`Request to ${resolvedUri} failed because this content seems to be blocklisted. Search VirusTotal for this URL to confirm: ${resolvedUri} `);
      return resOrErr;
    }
    console.warn(`Request to ${resolvedUri} failed with status ${resOrErr.status} - ${resOrErr.statusText}`);

    // if the status is 404 and we're using a thirdweb gateway url, return the response as is
    if (resOrErr.status === 404 && isTwGatewayUrl(resolvedUri)) {
      return resOrErr;
    }

    // these are the only errors that we want to retry, everything else we should just return the error as is
    // 408 - Request Timeout
    // 429 - Too Many Requests
    // 5xx - Server Errors
    if (resOrErr.status !== 408 && resOrErr.status !== 429 && resOrErr.status < 500) {
      return resOrErr;
    }

    // Since the current gateway failed, recursively try the next one we know about
    return this.download(uri, gatewayUrls, options, attempts + 1);
  }
}
const TOO_MANY_REQUESTS_TRACKER = new Map();
function isTooManyRequests(gatewayUrl) {
  return TOO_MANY_REQUESTS_TRACKER.has(gatewayUrl);
}
const TIMEOUT_MAP = new Map();
function tooManyRequestsBackOff(gatewayUrl, response) {
  // if we already have a timeout for this gateway url, clear it
  if (TIMEOUT_MAP.has(gatewayUrl)) {
    clearTimeout(TIMEOUT_MAP.get(gatewayUrl));
  }
  const retryAfter = response.headers.get("Retry-After");
  let backOff = 5000;
  if (retryAfter) {
    const retryAfterSeconds = parseInt(retryAfter);
    if (!isNaN(retryAfterSeconds)) {
      backOff = retryAfterSeconds * 1000;
    }
  }

  // track that we got a too many requests error
  TOO_MANY_REQUESTS_TRACKER.set(gatewayUrl, true);
  TIMEOUT_MAP.set(gatewayUrl, setTimeout(() => TOO_MANY_REQUESTS_TRACKER.delete(gatewayUrl), backOff));
}

/**
 * Default uploader used - handles uploading arbitrary data to IPFS
 *
 * @example
 * ```jsx
 * // Can instantiate the uploader with default configuration and your client ID when used in client-side applications
 * const uploader = new StorageUploader();
 * const clientId = "your-client-id";
 * const storage = new ThirdwebStorage({ clientId, uploader });
 *
 * // Can instantiate the uploader with default configuration and your secret key when used in server-side applications
 * const uploader = new StorageUploader();
 * const secretKey = "your-secret-key";
 * const storage = new ThirdwebStorage({ secretKey, uploader });
 *
 * // Or optionally, can pass configuration
 * const options = {
 *   // Upload objects with resolvable URLs
 *   uploadWithGatewayUrl: true,
 * }
 * const uploader = new StorageUploader(options);
 * const clientId = "your-client-id";
 * const storage = new ThirdwebStorage({ clientId, uploader });
 * ```
 *
 * @public
 */
class IpfsUploader {
  constructor(options) {
    this.uploadWithGatewayUrl = options?.uploadWithGatewayUrl || false;
    this.uploadServerUrl = options?.uploadServerUrl || TW_UPLOAD_SERVER_URL;
    this.clientId = options?.clientId;
    this.secretKey = options?.secretKey;
  }
  async uploadBatch(data, options) {
    if (options?.uploadWithoutDirectory && data.length > 1) {
      throw new Error("[UPLOAD_WITHOUT_DIRECTORY_ERROR] Cannot upload more than one file or object without directory!");
    }
    const formData = new FormData();
    const {
      form,
      fileNames
    } = this.buildFormData(formData, data, options);
    if (isBrowser()) {
      return this.uploadBatchBrowser(form, fileNames, options);
    } else {
      return this.uploadBatchNode(form, fileNames, options);
    }
  }
  buildFormData(form, files, options) {
    const fileNameToFileMap = new Map();
    const fileNames = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let fileName = "";
      let fileData = file;
      if (isFileInstance(file)) {
        if (options?.rewriteFileNames) {
          let extensions = "";
          if (file.name) {
            const extensionStartIndex = file.name.lastIndexOf(".");
            if (extensionStartIndex > -1) {
              extensions = file.name.substring(extensionStartIndex);
            }
          }
          fileName = `${i + options.rewriteFileNames.fileStartNumber}${extensions}`;
        } else {
          fileName = `${file.name}`;
        }
      } else if (isBufferOrStringWithName(file)) {
        fileData = file.data;
        if (options?.rewriteFileNames) {
          fileName = `${i + options.rewriteFileNames.fileStartNumber}`;
        } else {
          fileName = `${file.name}`;
        }
      } else {
        if (options?.rewriteFileNames) {
          fileName = `${i + options.rewriteFileNames.fileStartNumber}`;
        } else {
          fileName = `${i}`;
        }
      }

      // If we don't want to wrap with directory, adjust the filepath
      const filepath = options?.uploadWithoutDirectory ? `files` : `files/${fileName}`;
      if (fileNameToFileMap.has(fileName)) {
        // if the file in the map is the same as the file we are already looking at then just skip and continue
        if (isFileBufferOrStringEqual(fileNameToFileMap.get(fileName), file)) {
          // we add it to the filenames array so that we can return the correct number of urls,
          fileNames.push(fileName);
          // but then we skip because we don't need to upload it multiple times
          continue;
        }
        // otherwise if file names are the same but they are not the same file then we should throw an error (trying to upload to differnt files but with the same names)
        throw new Error(`[DUPLICATE_FILE_NAME_ERROR] File name ${fileName} was passed for more than one different file.`);
      }

      // add it to the map so that we can check for duplicates
      fileNameToFileMap.set(fileName, file);
      // add it to the filenames array so that we can return the correct number of urls
      fileNames.push(fileName);
      if (!isBrowser()) {
        form.append("file", fileData, {
          filepath
        });
      } else {
        // browser does blob things, filepath is parsed differently on browser vs node.
        // pls pinata?
        form.append("file", new Blob([fileData]), filepath);
      }
    }
    const metadata = {
      name: `Storage SDK`,
      keyvalues: {
        ...options?.metadata
      }
    };
    form.append("pinataMetadata", JSON.stringify(metadata));
    if (options?.uploadWithoutDirectory) {
      form.append("pinataOptions", JSON.stringify({
        wrapWithDirectory: false
      }));
    }
    return {
      form,
      // encode the file names on the way out (which is what the upload backend expects)
      fileNames: fileNames.map(fName => encodeURIComponent(fName))
    };
  }
  async uploadBatchBrowser(form, fileNames, options) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      let timer = setTimeout(() => {
        xhr.abort();
        reject(new Error("Request to upload timed out! No upload progress received in 30s"));
      }, 30000);
      xhr.upload.addEventListener("loadstart", () => {
        console.log(`[${Date.now()}] [IPFS] Started`);
      });
      xhr.upload.addEventListener("progress", event => {
        console.log(`[IPFS] Progress Event ${event.loaded}/${event.total}`);
        clearTimeout(timer);
        if (event.loaded < event.total) {
          timer = setTimeout(() => {
            xhr.abort();
            reject(new Error("Request to upload timed out! No upload progress received in 30s"));
          }, 30000);
        } else {
          console.log(`[${Date.now()}] [IPFS] Uploaded files. Waiting for response.`);
        }
        if (event.lengthComputable && options?.onProgress) {
          options?.onProgress({
            progress: event.loaded,
            total: event.total
          });
        }
      });
      xhr.addEventListener("load", () => {
        console.log(`[${Date.now()}] [IPFS] Load`);
        clearTimeout(timer);
        if (xhr.status >= 200 && xhr.status < 300) {
          let body;
          try {
            body = JSON.parse(xhr.responseText);
          } catch (err) {
            return reject(new Error("Failed to parse JSON from upload response"));
          }
          const cid = body.IpfsHash;
          if (!cid) {
            throw new Error("Failed to get IPFS hash from upload response");
          }
          if (options?.uploadWithoutDirectory) {
            return resolve([`ipfs://${cid}`]);
          } else {
            return resolve(fileNames.map(n => `ipfs://${cid}/${n}`));
          }
        }
        return reject(new Error(`Upload failed with status ${xhr.status} - ${xhr.responseText}`));
      });
      xhr.addEventListener("error", () => {
        console.log("[IPFS] Load");
        clearTimeout(timer);
        if (xhr.readyState !== 0 && xhr.readyState !== 4 || xhr.status === 0) {
          return reject(new Error("Upload failed due to a network error."));
        }
        return reject(new Error("Unknown upload error occured"));
      });
      xhr.open("POST", `${this.uploadServerUrl}/ipfs/upload`);
      if (this.secretKey) {
        xhr.setRequestHeader("x-secret-key", this.secretKey);
      } else if (this.clientId) {
        xhr.setRequestHeader("x-client-id", this.clientId);
      }
      setAnalyticsHeadersForXhr(xhr);

      // if we have a authorization token on global context then add that to the headers, this is for the dashboard.
      if (typeof globalThis !== "undefined" && "TW_AUTH_TOKEN" in globalThis && typeof globalThis.TW_AUTH_TOKEN === "string") {
        xhr.setRequestHeader("authorization", `Bearer ${globalThis.TW_AUTH_TOKEN}`);
      }

      // CLI auth token
      if (typeof globalThis !== "undefined" && "TW_CLI_AUTH_TOKEN" in globalThis && typeof globalThis.TW_CLI_AUTH_TOKEN === "string") {
        xhr.setRequestHeader("authorization", `Bearer ${globalThis.TW_CLI_AUTH_TOKEN}`);
        xhr.setRequestHeader("x-authorize-wallet", `true`);
      }
      xhr.send(form);
    });
  }
  async uploadBatchNode(form, fileNames, options) {
    if (options?.onProgress) {
      console.warn("The onProgress option is only supported in the browser");
    }
    const headers = {};
    if (this.secretKey) {
      headers["x-secret-key"] = this.secretKey;
    } else if (this.clientId) {
      headers["x-client-id"] = this.clientId;
    }

    // if we have a authorization token on global context then add that to the headers, this is for the dashboard.
    if (typeof globalThis !== "undefined" && "TW_AUTH_TOKEN" in globalThis && typeof globalThis.TW_AUTH_TOKEN === "string") {
      headers["authorization"] = `Bearer ${globalThis.TW_AUTH_TOKEN}`;
    }

    // CLI auth token
    if (typeof globalThis !== "undefined" && "TW_CLI_AUTH_TOKEN" in globalThis && typeof globalThis.TW_CLI_AUTH_TOKEN === "string") {
      headers["authorization"] = `Bearer ${globalThis.TW_CLI_AUTH_TOKEN}`;
      headers["x-authorize-wallet"] = "true";
    }
    setAnalyticsHeaders(headers);
    const res = await fetch(`${this.uploadServerUrl}/ipfs/upload`, {
      method: "POST",
      headers: {
        ...headers,
        ...form.getHeaders()
      },
      body: form.getBuffer()
    });
    if (!res.ok) {
      if (res.status === 401) {
        throw new Error("Unauthorized - You don't have permission to use this service.");
      }
      throw new Error(`Failed to upload files to IPFS - ${res.status} - ${res.statusText} - ${await res.text()}`);
    }
    const body = await res.json();
    const cid = body.IpfsHash;
    if (!cid) {
      throw new Error("Failed to upload files to IPFS - Bad CID");
    }
    if (options?.uploadWithoutDirectory) {
      return [`ipfs://${cid}`];
    } else {
      return fileNames.map(name => `ipfs://${cid}/${name}`);
    }
  }
}

/**
 * Upload and download files from decentralized storage systems.
 *
 * @example
 * ```jsx
 * // Create a default storage class with a client ID when used in client-side applications
 * const storage = new ThirdwebStorage({ clientId: "your-client-id" });
 *
 * // Create a default storage class with a secret key when used in server-side applications
 * const storage = new ThirdwebStorage({ secretKey: "your-secret-key" });
 *
 * You can get a clientId and secretKey from https://thirdweb.com/create-api-key
 *
 * // Upload any file or JSON object
 * const uri = await storage.upload(data);
 * const result = await storage.download(uri);
 *
 * // Or configure a custom uploader, downloader, and gateway URLs
 * const gatewayUrls = {
 *   // We define a mapping of schemes to gateway URLs
 *   "ipfs://": [
 *     "https://ipfs.thirdwebcdn.com/ipfs/",
 *     "https://cloudflare-ipfs.com/ipfs/",
 *     "https://ipfs.io/ipfs/",
 *   ],
 * };
 * const downloader = new StorageDownloader();
 * const uploader = new IpfsUploader();
 * const clientId = "your-client-id";
 * const storage = new ThirdwebStorage({ clientId, uploader, downloader, gatewayUrls });
 * ```
 *
 * @public
 */
class ThirdwebStorage {
  constructor(options) {
    this.uploader = options?.uploader || new IpfsUploader({
      clientId: options?.clientId,
      secretKey: options?.secretKey,
      uploadServerUrl: options?.uploadServerUrl
    });
    this.downloader = options?.downloader || new StorageDownloader({
      secretKey: options?.secretKey,
      clientId: options?.clientId
    });
    this.gatewayUrls = prepareGatewayUrls(parseGatewayUrls(options?.gatewayUrls), options?.clientId, options?.secretKey);
    this.clientId = options?.clientId;
  }

  /**
   * Resolve any scheme on a URL to get a retrievable URL for the data
   *
   * @param url - The URL to resolve the scheme of
   * @returns The URL with its scheme resolved
   *
   * @example
   * ```jsx
   * const uri = "ipfs://example";
   * const url = storage.resolveScheme(uri);
   * console.log(url);
   * ```
   */
  resolveScheme(url) {
    return replaceSchemeWithGatewayUrl(url, this.gatewayUrls, 0, this.clientId);
  }

  /**
   * Downloads arbitrary data from any URL scheme.
   *
   * @param url - The URL of the data to download
   * @returns The response object fetched from the resolved URL
   *
   * @example
   * ```jsx
   * const uri = "ipfs://example";
   * const data = await storage.download(uri);
   * ```
   */
  async download(url, options) {
    return this.downloader.download(url, this.gatewayUrls, options);
  }

  /**
   * Downloads JSON data from any URL scheme.
   * Resolves any URLs with schemes to retrievable gateway URLs.
   *
   * @param url - The URL of the JSON data to download
   * @returns The JSON data fetched from the resolved URL
   *
   * @example
   * ```jsx
   * const uri = "ipfs://example";
   * const json = await storage.downloadJSON(uri);
   * ```
   */
  async downloadJSON(url, options) {
    const res = await this.download(url, options);

    // If we get a JSON object, recursively replace any schemes with gatewayUrls
    const json = await res.json();
    return replaceObjectSchemesWithGatewayUrls(json, this.gatewayUrls, this.clientId);
  }

  /**
   * Upload arbitrary file or JSON data using the configured decentralized storage system.
   * Automatically uploads any file data within JSON objects and replaces them with hashes.
   *
   * @param data - Arbitrary file or JSON data to upload
   * @param options - Options to pass through to the storage uploader class
   * @returns  The URI of the uploaded data
   *
   * @example
   * ```jsx
   * // Upload file data
   * const file = readFileSync("../file.jpg");
   * const fileUri = await storage.upload(file);
   *
   * // Or upload a JSON object
   * const json = { name: "JSON", image: file };
   * const jsonUri = await storage.upload(json);
   * ```
   */
  async upload(data, options) {
    const [uri] = await this.uploadBatch([data], options);
    return uri;
  }

  /**
   * Batch upload arbitrary file or JSON data using the configured decentralized storage system.
   * Automatically uploads any file data within JSON objects and replaces them with hashes.
   *
   * @param data - Array of arbitrary file or JSON data to upload
   * @param options - Options to pass through to the storage uploader class
   * @returns  The URIs of the uploaded data
   *
   * @example
   * ```jsx
   * // Upload an array of file data
   * const files = [
   *  readFileSync("../file1.jpg"),
   *  readFileSync("../file2.jpg"),
   * ];
   * const fileUris = await storage.uploadBatch(files);
   *
   * // Upload an array of JSON objects
   * const objects = [
   *  { name: "JSON 1", image: files[0] },
   *  { name: "JSON 2", image: files[1] },
   * ];
   * const jsonUris = await storage.uploadBatch(objects);
   * ```
   */
  async uploadBatch(data, options) {
    data = data.filter(item => item !== undefined);
    if (!data.length) {
      return [];
    }
    const isFileArray = data.map(item => isFileOrBuffer(item) || typeof item === "string").every(item => !!item);
    let uris = [];

    // If data is an array of files, pass it through to upload directly
    if (isFileArray) {
      uris = await this.uploader.uploadBatch(data, options);
    } else {
      // Otherwise it is an array of JSON objects, so we have to prepare it first
      const metadata = (await this.uploadAndReplaceFilesWithHashes(data, options)).map(item => {
        if (typeof item === "string") {
          return item;
        }
        return JSON.stringify(item);
      });
      uris = await this.uploader.uploadBatch(metadata, options);
    }
    if (options?.uploadWithGatewayUrl || this.uploader.uploadWithGatewayUrl) {
      return uris.map(uri => this.resolveScheme(uri));
    } else {
      return uris;
    }
  }
  getGatewayUrls() {
    return this.gatewayUrls;
  }
  async uploadAndReplaceFilesWithHashes(data, options) {
    let cleaned = data;
    // Replace any gateway URLs with their hashes
    cleaned = replaceObjectGatewayUrlsWithSchemes(cleaned, this.gatewayUrls);

    // Recurse through data and extract files to upload
    const files = extractObjectFiles(cleaned);
    if (files.length) {
      // Upload all files that came from the object
      const uris = await this.uploader.uploadBatch(files, options);

      // Recurse through data and replace files with hashes
      cleaned = replaceObjectFilesWithUris(cleaned, uris);
    }
    if (options?.uploadWithGatewayUrl || this.uploader.uploadWithGatewayUrl) {
      // If flag is set, replace all schemes with their preferred gateway URL
      // Ex: used for Solana, where services don't resolve schemes for you, so URLs must be usable by default
      cleaned = replaceObjectSchemesWithGatewayUrls(cleaned, this.gatewayUrls, this.clientId);
    }
    return cleaned;
  }
}

/**
 * @internal
 */
class MockDownloader {
  gatewayUrls = DEFAULT_GATEWAY_URLS;
  constructor(storage) {
    this.storage = storage;
  }
  async download(url) {
    const [cid, name] = url.includes("mock://") ? url.replace("mock://", "").split("/") : url.replace("ipfs://", "").split("/");
    const data = name ? this.storage[cid][name] : this.storage[cid];
    return {
      async json() {
        return Promise.resolve(JSON.parse(data));
      },
      async text() {
        return Promise.resolve(data);
      }
    };
  }
}

/**
 * @internal
 */
class MockUploader {
  constructor(storage) {
    this.storage = storage;
  }
  async uploadBatch(data, options) {
    const cid = v4();
    const uris = [];
    this.storage[cid] = {};
    let index = options?.rewriteFileNames?.fileStartNumber || 0;
    for (const file of data) {
      let contents;
      if (isFileInstance(file)) {
        contents = await file.text();
      } else if (isBufferInstance(file)) {
        contents = file.toString();
      } else if (typeof file === "string") {
        contents = file;
      } else {
        contents = isBufferInstance(file.data) ? file.data.toString() : file.data;
        const name = file.name ? file.name : `file_${index}`;
        this.storage[cid][name] = contents;
        uris.push(`mock://${cid}/${name}`);
        continue;
      }
      this.storage[cid][index.toString()] = contents;
      uris.push(`mock://${cid}/${index}`);
      index += 1;
    }
    return uris;
  }
}

export { DEFAULT_GATEWAY_URLS, IpfsUploader, MockDownloader, MockUploader, PINATA_IPFS_URL, StorageDownloader, TW_UPLOAD_SERVER_URL, ThirdwebStorage, convertCidToV1, extractObjectFiles, getGatewayUrlForCid, isBrowser, isBufferInstance, isBufferOrStringWithName, isFileBufferOrStringEqual, isFileInstance, isFileOrBuffer, isTwGatewayUrl, parseGatewayUrls, prepareGatewayUrls, replaceGatewayUrlWithScheme, replaceObjectFilesWithUris, replaceObjectGatewayUrlsWithSchemes, replaceObjectSchemesWithGatewayUrls, replaceSchemeWithGatewayUrl };

import { a as assertWindowEthereum } from './assertWindowEthereum-88295886.browser.esm.js';

function getInjectedOneKeyProvider() {
  if (assertWindowEthereum(globalThis.window)) {
    if (globalThis.window.$onekey && globalThis.window.$onekey.ethereum) {
      return globalThis.window.$onekey.ethereum;
    }
  }
}

export { getInjectedOneKeyProvider as g };
